require('./bootstrap');

import $ from 'jquery';

import FileInput from './modules/fileinput';
import SelectTwo from './modules/select2';
import initModalConfirmation from './modules/modal-confirmation';
import 'emojionearea';


import 'select2';
import 'daterangepicker';
import sortable from 'jquery-ui/ui/widgets/sortable';


require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.colVis.js');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-buttons/js/buttons.flash.js');
require('datatables.net-buttons/js/buttons.print.js');


require('chart.js/dist/chart.min');
require('jquery-toast-plugin/dist/jquery.toast.min');



import tippy from 'tippy.js';

window.ClipboardJS = require('clipboard');
window.bootbox = require('bootbox');
window.tippy = tippy;
window.gmjs = require('./modules/gmjs');
window.datatables = require('./modules/databales');
window.short = require('./modules/short');



$(function () {
    FileInput();
    SelectTwo();
    initModalConfirmation();




    $('body .js-tooltip, [data-toggle="tooltip"]').tooltip({
        html: true
    });

    tippy('.js-lead-tooltip', {
        // default
        // parse `content` strings as HTML
        theme: 'light',
        allowHTML: true,
    });

    $('.btn-copy-clipboard').tooltip({trigger: 'click', placement: 'top'});

    function setTooltip(btn, message) {
        $(btn).tooltip('hide')
            .attr('data-original-title', message)
            .tooltip('show');
    }

    function hideTooltip(btn) {
        setTimeout(function() {
            $(btn).tooltip('hide');
        }, 1000);
    }

    var clipboardJs = new ClipboardJS('.btn-copy-clipboard');
    clipboardJs.on('success', function(e) {
        setTooltip(e.trigger, 'Copied!');
        hideTooltip(e.trigger);
    });
    clipboardJs.on('error', function(e) {
        setTooltip(e.trigger, 'Failed!');
        hideTooltip(e.trigger);
    });

    $('form .nav-link:not(.no-errors)').each(function(){
        let errors = $($(this).attr('href') + ' .invalid-feedback').length;
        if (errors) {
            let badge = ` <span class="badge badge-danger">${errors}</span>`;
            $(this).append(badge).addClass('text-danger');
        }
    });
});


// Utiliza el selector de atributos para obtener todos los elementos con el atributo data-emoji-picker
const emojiPickers = document.querySelectorAll('[data-emoji-picker]');

// Inicializa cada selector de emojis encontrado
emojiPickers.forEach(picker => {
    // Inicializa emojionearea en el campo de texto
    $(picker).emojioneArea({
        pickerPosition: "bottom", // Opcional: posición del selector de emojis
        tones: false, // Opcional: desactivar los tonos de piel de los emojis
        autocomplete: false // Opcional: desactivar el autocompletado de emojis
    });
});
