import $ from "jquery";
import moment from "moment";

/**
 * DateRangePicker with default options set
 * @param options
 */
export function dateRangePicker(options) {
    let dateRangePickerOptions = {
        autoUpdateInput: false,
        alwaysShowCalendars: true,
        firtDay:1,
        locale: {
            format: 'YYYY-MM-DD'
        },
        ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
            'Last 14 Days': [moment().subtract(15, 'days'), moment().subtract(1, 'days')],
            'Last 30 Days': [moment().subtract(30, 'days'), moment().subtract(1, 'days')],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
    }
    if (options.minDate !== undefined) {
        dateRangePickerOptions.minDate = options.minDate;
    }
    if (options.maxDate !== undefined) {
        dateRangePickerOptions.maxDate = options.maxDate;
    }
    $(`${options.selector}`).daterangepicker(dateRangePickerOptions, function (start, end, label) {
        $(`${options.selector}`).val(start.format('YYYY-MM-DD') + ' - ' + end.format('YYYY-MM-DD'));
    });
}

export function dateRangePickerWithHours(options) {
    let dateRangePickerOptions = {
        autoUpdateInput: false,
        alwaysShowCalendars: true,
        firtDay: 1,
        timePicker: true,  // Habilita la selección de la hora
        timePickerIncrement: 60,  // Incremento de minutos para la selección de la hora
        timePicker24Hour: true,  // Formato de 24 horas para la selección de la hora
        locale: {
            format: 'YYYY-MM-DD HH'  // Formato de fecha y hora
        },
        ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
            'Last 14 Days': [moment().subtract(15, 'days'), moment().subtract(1, 'days')],
            'Last 30 Days': [moment().subtract(30, 'days'), moment().subtract(1, 'days')],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
    };

    if (options.minDate !== undefined) {
        dateRangePickerOptions.minDate = options.minDate;
    }

    if (options.maxDate !== undefined) {
        dateRangePickerOptions.maxDate = options.maxDate;
    }

    $(`${options.selector}`).daterangepicker(dateRangePickerOptions, function (start, end, label) {
        $(`${options.selector}`).val(start.format('YYYY-MM-DD HH:mm') + ' - ' + end.format('YYYY-MM-DD HH:mm'));
    });
}

