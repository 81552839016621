export default function (){
    $('body').on('change','.custom-file-input', function() {
        let fileName = $(this).val().split('\\').pop();
        $(this).next('.custom-file-label').addClass("selected").html(fileName);
    });

    $('.js-add-file-input').on('click', function (event) {
        event.preventDefault();
        var name = $(this).closest('div').find('input[type="file"]').attr('name');
        var template = '<div class=" mt-3">' +
            '<div class="custom-file "> ' +
            ' <input type="file" class="custom-file-input " id="'+name+'" name="'+name+'" value=""> '+
            '<label class="custom-file-label" for="'+name+'">Choose file</label></div></div>';
        $(this).closest('div').append(template);
    });

}
