import $ from "jquery";
import moment from "moment";

/**
 * Toast with default options set
 * @param options
 */
export function toast(options) {
  $.toast({
    hideAfter: 5000,
    position: 'top-right',
    heading: options.heading,
    text: options.text,
    icon: options.icon
  });
}

/**
 * Select2 (ajax version) with default options set
 * @param options
 */
export function select2Ajax(options) {
  if (options.selected !== undefined && options.selected && options.selected.length) {
    $.ajax({
      headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
      url: options.url.replace('/select2', '/select2/selected'),
      type: 'get',
        multiple: Boolean(options.multiple || false),
      data: {q: options.selected},
      success: function (response) {
        if (response.id !== undefined) {
          $(`${options.selector}`).append($('<option>', {value: response.id, text: response.text}));
        }
      }
    });
  }

  $(`${options.selector}`).select2({
    theme: "bootstrap4",
    maximumSelectionSize: 10,
    minimumInputLength: 1,
    multiple: Boolean(options.multiple || false),
    allowClear: true,
    placeholder: options.placeholder || '',
    ajax: {
      delay: 250,
      type: 'get',
      url: options.url,
      allowClear: true,
      dataType: 'json',
      params: {
        contentType: "application/json"
      },
      data: function (term, page) {
        return {q: term};
      },
      processResults: function(data) {
        return {results: data}
      }
    },
    escapeMarkup: function (markup) { return markup; },
    templateResult: function (item) {
      if (item.loading) {
        return item.text;
      }
      return `<div>${item.text}</div>`;
    },
    templateSelection: function (item) {
      return item.text;
    }
  });
}

export function confirm(message, callback){
  bootbox.confirm({
    message: message,
    buttons: {
      confirm: {
        label: 'Yes',
        className: 'btn-danger'
      },
      cancel: {
        label: 'No',
        className: 'btn-secondary'
      }
    },
    callback: function (confirmed) {
      if (confirmed) {
        callback();
      }
    }
  });
}

/**
 * DateRangePicker with default options set
 * @param options
 */
export function dateRangePicker(options) {
  let format = options.format || 'YYYY-MM-DD';
  let dateRangePickerOptions = {
      autoUpdateInput: false,
      alwaysShowCalendars: true,
    locale: {
        format: format,
        firstDay: 1,
    },
    ranges: {
      'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 3 Days': [moment().subtract(3, 'days'), moment()],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }
  }
  if (options.minDate !== undefined) {
    dateRangePickerOptions.minDate = options.minDate;
  }
  if (options.maxDate !== undefined) {
    dateRangePickerOptions.maxDate = options.maxDate;
  }
  if (options.startDate !== undefined) {
    dateRangePickerOptions.startDate = options.startDate;
  }
  if (options.endDate !== undefined) {
    dateRangePickerOptions.endDate = options.endDate;
  }
  if (options.opens !== undefined) {
    dateRangePickerOptions.opens = options.opens;
  }
  let input = $(`${options.selector}`);
  input.daterangepicker(dateRangePickerOptions, function (start, end, label) {
    input.val(start.format(format) + ' - ' + end.format(format));
  });
  if (input.val() && input.val().includes(' - ')) {
    let parts = input.val().split(' - ');
    input.data('daterangepicker').setStartDate(parts[0]);
    input.data('daterangepicker').setEndDate(parts[1]);
  }else{
      input.data('daterangepicker').setStartDate(moment().subtract(3,'days'));
      input.data('daterangepicker').setEndDate(moment().subtract(2,'days'));
  }
}

export function singleDatePicker(options)
{
  let dateRangePickerOptions = {
    singleDatePicker: true,
    showDropdowns: true,
      firstDay: 1,
      minYear: moment().format('YYYY'),
  }

  if (options.minDate !== undefined) {
    dateRangePickerOptions.minDate = options.minDate;
  }
  if (options.maxDate !== undefined) {
    dateRangePickerOptions.maxDate = options.maxDate;
  }

  $(`${options.selector}`).daterangepicker(dateRangePickerOptions, function (start, end, label) {
  });
}

export function singleDatePickerHourly(options) {
    let dateRangePickerOptions = {
        singleDatePicker: true,
        showDropdowns: true,
        timePicker: true,  // Habilita la selección de la hora
        timePickerIncrement: 60,  // Intervalo de selección de la hora en minutos (aquí es 60 minutos para una hora)
        timePicker24Hour: true,  // Formato de 24 horas para la selección de la hora
        locale: {
            format: 'YYYY-MM-DD HH:mm', // Formato de fecha y hora
        },
        firstDay: 1,
        minYear: moment().format('YYYY'),
    }

    if (options.minDate !== undefined) {
        dateRangePickerOptions.minDate = options.minDate;
    }
    if (options.maxDate !== undefined) {
        dateRangePickerOptions.maxDate = options.maxDate;
    }

    $(`${options.selector}`).daterangepicker(dateRangePickerOptions, function (selectedDate, label) {
        // Acciones adicionales si es necesario
        console.log(selectedDate.format('YYYY-MM-DD HH:mm'));
    });
}


export function select2AjaxOFFER(options) {
    if (options.selected !== undefined && options.selected && options.selected.length) {
        $.ajax({
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
            url: options.url.replace('/select2', '/select2/selected'),
            type: 'get',
            multiple: Boolean(options.multiple || false),
            data: {q: options.selected},
            success: function (response) {
                if (response.id !== undefined) {
                    $(`${options.selector}`).append($('<option>', {value: response.id, text: response.text}));
                }
            }
        });
    }

    $(`${options.selector}`).select2({
        theme: "bootstrap4",
        maximumSelectionSize: 10,
        minimumInputLength: 1,
        multiple: Boolean(options.multiple || false),
        allowClear: true,
        placeholder: options.placeholder || '',
        ajax: {
            delay: 250,
            type: 'get',
            url: options.url,
            allowClear: true,
            dataType: 'json',
            params: {
                contentType: "application/json"
            },
            data: function (term, page) {
                return {q: term , company_id: $('#company_id').val()};
            },
            processResults: function(data) {
                return {results: data}
            }
        },
        escapeMarkup: function (markup) { return markup; },
        templateResult: function (item) {
            if (item.loading) {
                return item.text;
            }
            return `<div>${item.text}</div>`;
        },
        templateSelection: function (item) {
            return item.text;
        }
    });
}

export function select2AjaxTrafficSource(options) {
    if (options.selected !== undefined && options.selected && options.selected.length) {
        $.ajax({
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
            url: options.url.replace('/select2', '/select2/selected'),
            type: 'get',
            multiple: Boolean(options.multiple || false),
            data: {q: options.selected},
            success: function (response) {
                if (response.id !== undefined) {
                    $(`${options.selector}`).append($('<option>', {value: response.id, text: response.text}));
                }
            }
        });
    }

    $(`${options.selector}`).select2({
        theme: "bootstrap4",
        maximumSelectionSize: 10,
        minimumInputLength: 1,
        multiple: Boolean(options.multiple || false),
        allowClear: true,
        placeholder: options.placeholder || '',
        ajax: {
            delay: 250,
            type: 'get',
            url: options.url,
            allowClear: true,
            dataType: 'json',
            params: {
                contentType: "application/json"
            },
            data: function (term, page) {
                return {q: term , company_id: $('#company_id').val()};
            },
            processResults: function(data) {
                return {results: data}
            }
        },
        escapeMarkup: function (markup) { return markup; },
        templateResult: function (item) {
            if (item.loading) {
                return item.text;
            }
            return `<div>${item.text}</div>`;
        },
        templateSelection: function (item) {
            return item.text;
        }
    });
}
