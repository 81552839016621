import $ from "jquery";


/**
 * Find Search in datatables
 * @param table
 * @param idData
 * @param nameField
 */
export function simpleSearch(table , idData, nameField)
{

    let data = $(idData).val();

    if(!data) {
        data = '';
    }

    if (data !== table.column(nameField).search()) {
        if (data !== table.column(nameField).search()) {
            table.column(nameField).search(data).draw();
        }
    }
}
