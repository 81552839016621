import $ from "jquery";

export default function () {
    $('body').on('click','.js-open-modal-confirmation',function (event) {
        event.preventDefault();
        $('#confirmation-action-modal').modal('show');
        let title = $(this).data('title');
        let text = $(this).data('text');
        let buttonText = $(this).data('button-text');
        let href = $(this).attr('href');

        console.log(href);
        $('.js-title-confirmation').text(title);
        $('.js-text-confirmation').text(text);
        $('.js-button-confirmation').text(buttonText);
        $('.js-button-confirmation').attr('href', href);

    });


}
