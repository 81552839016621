import $ from "jquery";

export default function () {


    function initSelect2(element) {
        element.select2({
            sortable: true,
            theme: element.attr('multiple') ? 'default' : 'bootstrap4',
            dropdownParent: element.parent(),
            width: element.data('width') ? element.data('width') : element.hasClass('w-100') ? '100%' : 'style',
            placeholder: element.data('placeholder'),
            allowClear: Boolean(element.data('allow-clear')),
        });

        var ul = element.next().find('.select2-selection__rendered');
        ul.sortable({
            containment: 'parent',
            tolerance: 'intersect',
            delay: 150,
            stop: function() {
                ul.find('.select2-selection__choice').each(function() {
                    var title = $(this).attr('title');
                    var option = element.find('option:contains("' + title + '")');
                    option.detach().appendTo(element);
                });
                element.trigger('change');
            }
        });
    }



    $('.js-select2').each(function() {
        initSelect2($(this));
    })



    $(".js-select-two-tag").select2({
        tags: true,
        tokenSeparators: ['|', '|'],
        theme: 'bootstrap4',
        width: $(this).data('width') ? $(this).data('width') : $(this).hasClass('w-100') ? '100%' : 'style',
        placeholder: $(this).data('placeholder'),
        allowClear: Boolean($(this).data('allow-clear')),
        closeOnSelect: !$(this).attr('multiple'),
        language:{
            noResults : function () { return ''; }
        }
    });
}
